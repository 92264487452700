/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ComponentRef, FC, FocusEvent, FocusEventHandler, KeyboardEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Editable, RenderElementProps, RenderLeafProps, Slate, withReact } from 'slate-react';
import { createEditor, Descendant, Editor, Node, Path, Transforms } from 'slate';
import { v4 as uuid } from 'uuid';
import { withHistory } from 'slate-history';
import { dataAttributeProps } from '../../../utils/ComponentUtils';
import { InputStyle } from '../form-control/Input';
import PlaceholderWord, { PlaceholderWordProps } from './PlaceholderWord';
import PlaceholderSelectMenu, { MenuAction } from './PlaceholderSelectMenu';
import { FormBuilderForm, FormBuilderPlaceholder } from '../../form-builder/FormBuilderTypes';
import { SupportedLanguage } from '../../../types/Languages';
import { FormConfig } from '../../../models/Form';
import { useDebugHotkey } from '../../../hooks/useDebugHotkey';
import ObjectUtils from '../../../utils/ObjectUtils';
import { useTranslation } from 'react-i18next';
import { parseMarkdownToWords, parseSlateJsToMarkdown, parseTextToWords, toggleFormatting } from '../../../utils/PlaceholderUtils';
import MarkdownRenderers from '../../../utils/MarkdownRenderers';
import RichTextActionBar from './RichTextActionBar';
import isHotkey from 'is-hotkey';
import { CustomText } from '../../../slate';
import { Translations } from '../../../models/Translation';
import TranslatableInputButtons from '../TranslatableInputButtons';
import debounce from 'lodash.debounce';
import { useAccordionContext } from '../accordion/Accordion';
import { nextTick } from '../../../utils/ReactUtils';

const leafRenderer = MarkdownRenderers({});

const withPlaceholders = (
  editor: Editor,
  clonePlacholders: (values: Record<string, string>) => void,
  isSingleLine: boolean,
  enableMarkdownPaste: boolean,
) => {
  const { isVoid, isInline, insertData, normalizeNode } = editor;
  editor.normalizeNode = (entry) => {
    const [node, path] = entry;
    const prevNodePath = Path.hasPrevious(path) ? Path.previous(path) : null;
    const prevNode = prevNodePath && Node.get(editor, prevNodePath);

    if (!('type' in node)) {
      Transforms.setNodes(editor, { type: 'word' }, { at: path });
      return;
    }

    // Remove empty links
    if (node.type === 'link' && node.children.every((x) => x.text === '')) {
      Transforms.removeNodes(editor, { at: path });
      return;
    }

    // Remove empty lists
    if (node.type === 'unordered-list' || node.type === 'ordered-list') {
      if (node.children.every((x) => x.type !== 'li')) {
        Transforms.removeNodes(editor, { at: path });
        return;
      }
    }

    // merge adjacent lists of the same type
    if (prevNode && 'type' in prevNode && ['unordered-list', 'ordered-list'].includes(node.type) && node.type === prevNode.type) {
      Transforms.mergeNodes(editor, { at: path, hanging: true });
      editor.selection && Transforms.select(editor, editor.selection);
      return;
    }

    // Move a heading to the root
    if (node.type === 'heading' && path.length > 1) {
      Transforms.moveNodes(editor, { at: path, to: Path.next([path[0]]) });
      return;
    }

    // Wrap words in paragraphs if in root of editor
    if (node.type === 'word' && path.length <= 1) {
      Transforms.wrapNodes(editor, { type: 'paragraph', children: [] }, { at: path });
      return;
    }

    normalizeNode(entry);
  };

  editor.insertData = (data) => {
    // ability to paste placeholders - will give it a new id
    const slateData = data.getData('application/x-slate-fragment');
    if (slateData && enableMarkdownPaste) {
      const slateNodes = JSON.parse(decodeURIComponent(atob(slateData).toString()));
      const newIds = (data: any[]) => {
        let oldToNewPlaceholderMap: Record<string, string> = {};
        for (const node of data) {
          if ('children' in node) {
            oldToNewPlaceholderMap = { ...oldToNewPlaceholderMap, ...newIds(node.children) };
          }

          if ('id' in node) {
            if (node.type === 'placeholderContainer') {
              oldToNewPlaceholderMap[node.id] = '${{' + uuid() + '}}';
              node.children.text = oldToNewPlaceholderMap[node.id];
              node.id = oldToNewPlaceholderMap[node.id];
            } else {
              node.id = uuid();
            }
          }
        }
        return oldToNewPlaceholderMap;
      };
      const oldToNewMap = newIds(slateNodes);
      clonePlacholders(oldToNewMap);

      Transforms.insertNodes(editor, slateNodes);
      return;
    }

    // paste normal text
    const text = data.getData('text/plain');
    if (text) {
      Transforms.insertText(editor, isSingleLine ? text.replaceAll('\n', ' ') : text);
      return;
    }

    insertData(data);
  };

  editor.isVoid = (element) => {
    return ['placeholderContainer', 'tag', 'thematic-break'].includes(element.type) ? true : isVoid(element);
  };

  editor.isInline = (element) => {
    return ['placeholderContainer', 'link'].includes(element.type) ? true : isInline(element);
  };

  return editor;
};

type InputProps = {
  form: FormBuilderForm;
  referencedForms: Record<string, FormConfig>;
  action: MenuAction;
  initialValue?: string;
  singleLine?: boolean;
  label?: string;
  inputPlaceholder?: string;
  autoFocus?: boolean;
  disabled?: boolean;
  style?: InputStyle;
  errorState?: boolean;
  maxLength?: number;
  enableMarkdown?: boolean;
  enableDynamicData?: boolean;
  onPlaceholdersChange: (value: FormBuilderPlaceholder[]) => void;
  toolbarPosition?: 'top' | 'bottom';
  rows?: number;
  wordTagPlugin?: FC<PlaceholderWordProps>;
  opacity?: number;
  onBlur?: (event: FocusEvent<HTMLDivElement>) => void;
  onFocus?: () => void;
  allowExternalDynamicData?: boolean;
  onRemovedPlaceholder?: (placeholder: string) => void;
} & (
  | {
      enableLanguageToggle?: false | undefined;
      onTextChange: (value: string) => void;
    }
  | {
      enableLanguageToggle: true;
      translations: Translations;
      translationKey: string;
      onTranslationsChange: (newTranslations: Translations) => void;
    }
);

export const PLACEHOLDER_TEXT_BOX_HOTKEYS = [
  { keys: 'mod+b', mark: 'strong' },
  { keys: 'mod+i', mark: 'em' },
] as const;

const HOTKEYS = PLACEHOLDER_TEXT_BOX_HOTKEYS.map((x) => ({ ...x, keys: isHotkey(x.keys) }));

const PlaceholderTextBox: FC<InputProps> = (props) => {
  const {
    action,
    initialValue: initialValueExternal = '',
    singleLine,
    label,
    inputPlaceholder,
    autoFocus,
    disabled,
    style,
    errorState,
    maxLength,
    form,
    onPlaceholdersChange,
    referencedForms,
    enableMarkdown,
    enableLanguageToggle,
    toolbarPosition = 'top',
    rows,
    wordTagPlugin: Word = PlaceholderWord,
    opacity,
    onBlur: onBlurExternal,
    onFocus: onFocusExternal,
    enableDynamicData = true,
    allowExternalDynamicData,
    onRemovedPlaceholder,
  } = props;

  const [initialValue] = useState(initialValueExternal);
  const {
    t,
    i18n: { language: userLanguage },
  } = useTranslation('form-builder');
  const editorRef = useRef<HTMLDivElement>(null);
  const debug = useDebugHotkey();

  const logSlateElement = useCallback((node: any) => {
    console.groupCollapsed(node.type);
    // eslint-disable-next-line no-console
    console.log(node);
    console.groupEnd();
  }, []);

  const clonePlacholders = useCallback(
    (oldToNewMap: Record<string, string>) => {
      const newPlaceholders = [];
      for (const oldId in oldToNewMap) {
        const newId = oldToNewMap[oldId];

        const oldPlaceholder = ObjectUtils.DeepClone(form.placeholders?.find((x) => x.placeholder === oldId));
        if (!oldPlaceholder) {
          continue;
        }

        oldPlaceholder.placeholder = newId;
        newPlaceholders.push(oldPlaceholder);
      }

      onPlaceholdersChange([...(form.placeholders || []), ...newPlaceholders]);
    },
    [form.placeholders, onPlaceholdersChange],
  );

  const [editorLanguage, setEditorLanguage] = useState(userLanguage as SupportedLanguage);

  useEffect(() => {
    setEditorLanguage(userLanguage as SupportedLanguage);
  }, [userLanguage]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const editorInternal = useMemo(() => withReact(withHistory(createEditor())), [editorLanguage]);
  const editor = useMemo(
    () => withPlaceholders(editorInternal, clonePlacholders, !!singleLine, !!enableMarkdown),
    [clonePlacholders, editorInternal, enableMarkdown, singleLine],
  );

  const [hasFocus, setHasFocus] = useState(autoFocus);
  const menu = useRef<ComponentRef<typeof PlaceholderSelectMenu>>(null);
  const editingTag = useRef<HTMLDivElement | null>(null);

  const translationKey = props.enableLanguageToggle ? props.translationKey : undefined;
  const translations = props.enableLanguageToggle ? props.translations : undefined;
  const onTranslationsChange = props.enableLanguageToggle ? props.onTranslationsChange : undefined;
  const onTextChange = props.enableLanguageToggle ? undefined : props.onTextChange;

  const onTagDelete = useCallback(
    (placeholder: string) => {
      onPlaceholdersChange(form.placeholders?.filter((x) => x.placeholder !== placeholder) ?? []);
    },
    [form.placeholders, onPlaceholdersChange],
  );

  const renderElement = useCallback(
    (props: RenderElementProps) => {
      if (props.element.type === 'tag' || props.element.type === 'word') {
        return debug ? (
          <span data-debug-el="true" className={'m-[1px] border border-indigo-400'} {...props.attributes}>
            {props.children}
          </span>
        ) : (
          props.children
        );
      } else if (props.element.type === 'paragraph') {
        const pTag = leafRenderer.p({ ...props, ignorePadding: !enableMarkdown });
        return debug ? (
          <span data-debug-el="true" onClick={() => logSlateElement(props.element)} className="m-[1px] block border border-cyan-400">
            {pTag}
          </span>
        ) : (
          pTag
        );
      } else if (['unordered-list', 'ordered-list'].indexOf(props.element.type) > -1) {
        const listTag = (props.element.type === 'ordered-list' ? leafRenderer.ol : leafRenderer.ul)(props);
        return debug ? (
          <span data-debug-el="true" onClick={() => logSlateElement(props.element)} className="m-[1px] block border border-lime-500">
            {listTag}
          </span>
        ) : (
          listTag
        );
      } else if (props.element.type === 'li') {
        return debug ? (
          <span data-debug-el="true" onClick={() => logSlateElement(props.element)} className="m-[1px] block border border-purple-700">
            {leafRenderer.li(props)}
          </span>
        ) : (
          leafRenderer.li(props)
        );
      } else if (props.element.type === 'blockquote') {
        return debug ? (
          <span data-debug-el="true" onClick={() => logSlateElement(props.element)} className="m-[1px] block border border-orange-700">
            {leafRenderer.blockquote(props)}
          </span>
        ) : (
          leafRenderer.blockquote(props)
        );
      } else if (props.element.type === 'heading') {
        const h = ((leafRenderer as any)[`h${props.element.level}`] as any)({ ...props, level: props.element.level });
        return debug ? (
          <span data-debug-el="true" onClick={() => logSlateElement(props.element)} className="m-[1px] block border border-green-400">
            {h}
          </span>
        ) : (
          h
        );
      } else if (props.element.type === 'link') {
        return (
          <span data-debug-el="true" title={props.element.url} className="text-link-1 font-medium hover:underline">
            {props.children}
          </span>
        );
      } else if (props.element.type === 'thematic-break') {
        return MarkdownRenderers({}).hr(props);
      } else if (props.element.type === 'placeholderContainer') {
        const word = (
          <Word
            form={form}
            referencedForms={referencedForms}
            editor={editor}
            id={(props.element as any).id}
            isTag
            onEdit={() => {
              if (!('id' in props.element)) return;
              const selectionPosition = [...Node.descendants(editor)].find(
                (x) => x[0].type === 'tag' && x[0].text.trimEnd() === (props.element as any).id,
              );
              menu.current?.trigger(props.element.id);
              if (selectionPosition) {
                const position = { path: selectionPosition[1], offset: 0 };
                Transforms.select(editor, {
                  anchor: position,
                  focus: position,
                });
              }
              editingTag.current = document.querySelector('[data-tag-id="' + props.element.id + '"]');
            }}
            onDelete={() => ('id' in props.element ? onTagDelete(props.element.id) : undefined)}
          >
            {props.children}
          </Word>
        );
        if (!debug) {
          return word;
        }

        return (
          <span data-debug-el="true" className="m-[1px] inline-block border border-orange-400" onClick={() => logSlateElement(props.element)}>
            {word}
          </span>
        );
      }

      return <span className="bg-red-500 text-white">ERROR</span>;
    },
    [Word, debug, editor, enableMarkdown, form, logSlateElement, onTagDelete, referencedForms],
  );

  const renderLeaf = useCallback(
    (props: RenderLeafProps) => {
      if (props.leaf.type === 'thematic-break') {
        return MarkdownRenderers({}).hr(props);
      }

      if (props.leaf.type === 'break') {
        return <div />;
      }

      const word =
        props.leaf.type !== 'word' ? (
          <span className="bg-red-500 text-white">ERROR</span>
        ) : (
          <Word
            referencedForms={referencedForms}
            form={form}
            key={props.leaf.id}
            id={props.leaf.id}
            styles={'styles' in props.leaf ? props.leaf.styles : []}
            textboxDisabled={disabled}
          >
            {props.children}
          </Word>
        );

      if (!debug) {
        return <span {...props.attributes}>{word}</span>;
      }

      return (
        <span
          data-debug-el="true"
          {...props.attributes}
          onClick={() => logSlateElement(props.leaf)}
          className={`${debug && 'm-[1px] border border-red-500'}`}
        >
          {word}
        </span>
      );
    },
    [Word, debug, disabled, form, logSlateElement, referencedForms],
  );

  const value = useMemo<Descendant[]>(() => {
    const text = enableLanguageToggle ? translations?.[editorLanguage]?.[translationKey ?? ''] ?? '' : initialValue ?? '';

    if (!enableMarkdown) {
      return parseTextToWords(text);
    }

    return parseMarkdownToWords(text);
  }, [editorLanguage, enableMarkdown, initialValue, enableLanguageToggle, translationKey, translations]);

  const currentClickEvent = useRef<Event | null>(null);
  useEffect(() => {
    if (!debug || !hasFocus) return;

    const refToEditor = editorRef.current;
    const clickStart = (e: Event) => {
      if (currentClickEvent.current !== e) {
        console.groupCollapsed('Slate element click');
      }
      currentClickEvent.current = e;
    };

    const clickEnd = () => {
      console.groupEnd();
      currentClickEvent.current = null;
    };

    const debugEls = [...(refToEditor?.querySelectorAll('[data-debug-el="true"]') ?? [])];
    debugEls.forEach((el) => {
      (el as HTMLElement).addEventListener('click', clickStart);
    });

    document.body.addEventListener('mouseup', clickEnd);

    return () => {
      debugEls.forEach((el) => {
        (el as HTMLElement).removeEventListener('click', clickStart);
      });
      document.body.removeEventListener('mouseup', clickEnd);

      console.groupEnd();
    };
  }, [debug, hasFocus, value]);

  const [internalState, setInternalState] = useState<Descendant[]>(value);

  const emitUpdateInternal = useCallback(
    (updatedValue?: Descendant[]) => {
      const newValue = parseSlateJsToMarkdown(updatedValue || internalState);
      const currentValue = parseSlateJsToMarkdown(value);
      if (newValue.trim() === currentValue.trim()) {
        return;
      }

      onTextChange && onTextChange(newValue);

      onTranslationsChange &&
        onTranslationsChange({
          ...(translations ?? {}),
          [editorLanguage]: {
            ...((translations ?? {})[editorLanguage] ?? {}),
            [translationKey ?? '']: newValue,
          },
        });
    },
    [editorLanguage, internalState, onTextChange, onTranslationsChange, translationKey, translations, value],
  );

  const emitUpdate = debounce(emitUpdateInternal, 15);

  const { updateParentHeight: updateParentAcordion } = useAccordionContext();
  const onFocus = useCallback(() => {
    if (disabled) {
      const editorDiv = editorRef.current?.querySelector('[data-slate-editor="true"]') as HTMLElement;
      editorDiv.blur();
      return;
    }

    setHasFocus(true);
    onFocusExternal && onFocusExternal();
    nextTick(() => {
      updateParentAcordion();
    });
  }, [disabled, onFocusExternal, updateParentAcordion]);

  const blured = useRef(false); // used to stop double blur-ing of editor when clicking outside
  const onBlur = useCallback<FocusEventHandler<HTMLDivElement>>(
    (e) => {
      const alreadyBlurred = blured.current;

      blured.current = true;
      setHasFocus(false);

      if (!alreadyBlurred) {
        emitUpdate();
        onBlurExternal && onBlurExternal(e);
      }
    },
    [emitUpdate, onBlurExternal],
  );

  useEffect(() => {
    const handler = (e: MouseEvent) => {
      if (editorRef.current?.contains(e.target as HTMLElement)) return;
      if (hasFocus && !blured.current) {
        onBlur(null!);
      }
    };

    window.addEventListener('mousedown', handler);
    return () => {
      window.removeEventListener('mousedown', handler);
    };
  }, [hasFocus, onBlur]);

  useEffect(() => {
    blured.current = !hasFocus;
  }, [hasFocus]);

  const prevValue = useRef<Descendant[] | null>(null);

  useEffect(() => {
    prevValue.current = null;
  }, [editorLanguage]);

  const onChange = useCallback(
    (value: Descendant[]) => {
      const getLeaves = (node: Descendant): CustomText[] => {
        if ('children' in node) {
          return node.children.flatMap((x) => getLeaves(x));
        }
        return [node as CustomText];
      };
      const oldLeaves = prevValue.current?.flatMap((x) => getLeaves(x));
      const newLeaves = value.flatMap((x) => getLeaves(x));

      if (prevValue.current !== null && newLeaves.length < (oldLeaves?.length ?? 0)) {
        const removedLeaves = oldLeaves?.filter((x) => !newLeaves.includes(x)) ?? [];
        for (const leaf of removedLeaves) {
          if (leaf.type === 'tag') {
            onRemovedPlaceholder && onRemovedPlaceholder(leaf.text);
            onTagDelete(leaf.text);
          }
        }
      }

      setInternalState(value);
      emitUpdate(value);
      prevValue.current = value;
    },
    [emitUpdate, onRemovedPlaceholder, onTagDelete],
  );

  const styleClasses = useMemo(() => {
    switch (style) {
      case InputStyle.COMPOUND:
        return 'p-2 my-0 outline-none rounded-lg';

      case InputStyle.MINIMAL:
        return 'p-1 outline-none rounded-lg text-dpm-14';

      case InputStyle.NORMAL:
      default:
        return 'p-2 outline-none rounded-lg';
    }
  }, [style]);

  const enterTrigger = useCallback(
    (e: KeyboardEvent<HTMLDivElement>) => {
      if (singleLine) {
        e.preventDefault();
        return;
      }

      // allow the enter to happen - do nothing about it
      if (e.shiftKey) {
        return;
      }

      if (!editor.selection) {
        return;
      }

      const selectedNode = [...Editor.nodes(editor, { at: editor.selection, match: (n) => 'type' in n && n.type === 'word' })][0];
      if (!selectedNode) return;

      const nodeTree = [...Node.ancestors(editor, selectedNode[1], { reverse: true })];
      const nearestBlockquote = nodeTree.find((x) => (x[0] as any).type === 'blockquote');
      const nearestHeading = nodeTree.find((x) => (x[0] as any).type === 'heading');
      const nearestLi = nodeTree.find((x) => (x[0] as any).type === 'li');

      // "Escape" out of a blockquote by pressing enter twice
      if (nearestBlockquote) {
        const pTags = [...Node.children(editor, nearestBlockquote[1])];
        const lastPTag = pTags[pTags.length - 1];

        if (
          lastPTag[0].type === 'paragraph' &&
          lastPTag[0].children.length === 1 &&
          lastPTag[0].children[0].type === 'word' &&
          lastPTag[0].children[0].text === ''
        ) {
          e.preventDefault();
          Transforms.splitNodes(editor, { at: lastPTag[1] });
          Transforms.unwrapNodes(editor, { at: Path.next(Path.parent(lastPTag[1])) });
        }
      }

      // "escape" out of a heading when pressing enter
      else if (nearestHeading) {
        setTimeout(() => {
          Transforms.setNodes(editor, { type: 'paragraph' as any, level: undefined }, { at: Path.next(nearestHeading[1]) });
        }, 0);
      }

      // add item to list if pressing enter in one
      else if (nearestLi) {
        e.preventDefault();

        const paragraphPath = Path.parent(editor.selection.anchor.path);
        const isAtStartOfParagraph = editor.selection.anchor.offset === 0 && editor.selection.anchor.path.slice(0).reverse()[0] === 0;
        let isAtEndOfParagraph = false;
        const inMiddleOfParagraph =
          !isAtStartOfParagraph &&
          (editor.selection.anchor.path.slice().reverse()[0] < [...Node.children(editor, paragraphPath)].length - 1
            ? true
            : editor.selection.anchor.offset < ('text' in selectedNode[0] ? selectedNode[0].text.length : 0))
            ? (isAtEndOfParagraph = false) || true
            : (isAtEndOfParagraph = true) && false;
        Transforms.splitNodes(editor, { at: editor.selection });

        let liPath = Path.parent(paragraphPath);
        liPath = isAtStartOfParagraph ? liPath : Path.next(liPath);

        const liChildren: CustomText[] =
          isAtStartOfParagraph || inMiddleOfParagraph ? [] : [{ type: 'paragraph', children: [{ id: uuid(), type: 'word', text: '' }] }];
        Transforms.insertNodes(editor, { type: 'li', children: liChildren }, { at: liPath });

        if (inMiddleOfParagraph) {
          Transforms.moveNodes(editor, { at: isAtStartOfParagraph ? paragraphPath : Path.next(paragraphPath), to: [...liPath, 0] });
        } else {
          Transforms.select(editor, { offset: 0, path: [...liPath, 0, 0] });
        }

        if (isAtStartOfParagraph && isAtEndOfParagraph) {
          Transforms.insertNodes(editor, { type: 'paragraph', children: [] }, { at: paragraphPath });
        }
      }
    },
    [editor, singleLine],
  );

  const backspaceTrigger = useCallback(
    (e: KeyboardEvent<HTMLDivElement>) => {
      if (!editor.selection) {
        return;
      }

      const selectedNodes = [...Editor.nodes(editor, { at: editor.selection, match: (n) => 'type' in n && n.type === 'word' })];
      if (!selectedNodes[0]) return;

      const nodeTree = [...Node.ancestors(editor, selectedNodes[0][1], { reverse: true })];
      const heading: any = nodeTree.find((x) => (x[0] as any).type === 'heading');
      const blockquote: any = nodeTree.find((x) => (x[0] as any).type === 'blockquote');
      const li = nodeTree.find((x) => (x[0] as any).type === 'li');
      const liEl = li?.[0] as any;
      const liPath = li?.[1];

      // "Escape" out of an empty heading
      if (heading && heading[0].children.length === 1 && heading[0].children[0].text.length === 0) {
        e.preventDefault();
        Transforms.setNodes(editor, { type: 'paragraph', level: undefined } as any, { at: heading[1] });
      }

      // Escape out of an empty blockquote
      else if (
        blockquote &&
        blockquote[0].children.length === 1 &&
        blockquote[0].children[0].children.length === 1 &&
        blockquote[0].children[0].children[0].text.length === 0
      ) {
        e.preventDefault();
        Transforms.unwrapNodes(editor, { at: blockquote[1] });
      }

      // "Escape" out of a list if pressing backspace in one
      else if (liEl && liPath && editor.selection?.focus.offset === 0 && Path.equals(editor.selection?.focus.path, [...liPath, 0, 0])) {
        e.preventDefault();
        Transforms.splitNodes(editor, {
          at: liPath,
        });
        const firstListPath = nodeTree.find((x) => (x[0] as any).type === 'unordered-list' || (x[0] as any).type === 'ordered-list')?.[1] as number[];
        const firstLiOfSecondList = [...Node.children(editor, Path.next(firstListPath))][0][1];

        if (Node.has(editor, Path.next(firstLiOfSecondList))) {
          Transforms.splitNodes(editor, {
            at: Path.next(firstLiOfSecondList),
          });
        }
        Transforms.unwrapNodes(editor, {
          at: firstLiOfSecondList,
        });
        Transforms.unwrapNodes(editor, { at: Path.next(firstListPath) });
      }
    },
    [editor],
  );

  const spaceTrigger = useCallback(
    (e: KeyboardEvent<HTMLDivElement>) => {
      if (!editor.selection) {
        return;
      }

      const selectedNodes = [...Editor.nodes(editor, { at: editor.selection, match: (n) => 'type' in n && n.type === 'word' })];
      const selectedNode = selectedNodes?.[0];
      const nodeTree = [...Node.ancestors(editor, selectedNode[1])];
      const paragraph = nodeTree.find((x) => (x[0] as any).type === 'paragraph');
      if (!paragraph) return;

      const nodeText = 'text' in selectedNode[0] ? selectedNode[0].text.trim() : null;
      // Turn the `- ` into an unordered point
      if (nodeText?.startsWith('-') && !nodeText.includes('--')) {
        e.preventDefault();
        Editor.deleteBackward(editor, { unit: 'character' });
        Transforms.wrapNodes(editor, { type: 'li', children: [] }, { at: paragraph[1] });
        Transforms.wrapNodes(editor, { type: 'unordered-list', children: [] }, { at: paragraph[1] });
      } else if (nodeText?.startsWith('---')) {
        Editor.deleteBackward(editor, { unit: 'word' });
        Transforms.insertNodes(editor, { type: 'thematic-break', children: [{ id: uuid(), text: '', type: 'word' }] });
      }
      // Turn the `> ` into a blockquote
      else if (nodeText?.startsWith('>')) {
        e.preventDefault();
        Editor.deleteBackward(editor, { unit: 'character' });
        Transforms.wrapNodes(editor, { type: 'blockquote', children: [] }, { at: paragraph[1] });
      }
      // Turn "1." into an ordered list
      else if (nodeText?.startsWith('1.')) {
        e.preventDefault();
        Editor.deleteBackward(editor, { unit: 'word' });
        Transforms.wrapNodes(editor, { type: 'li', children: [] }, { at: paragraph[1] });
        Transforms.wrapNodes(editor, { type: 'ordered-list', children: [] }, { at: paragraph[1] });
      }
      // Turn #'s into a heading
      else if (nodeText?.match(/^#{1,6}$/)) {
        e.preventDefault();
        Editor.deleteBackward(editor, { unit: 'word' });
        const level = nodeText.length;
        let path = paragraph[1];
        if (nodeTree.find((x) => 'type' in x[0] && x[0].type === 'li')) {
          Editor.insertBreak(editor);
          path = Path.next(path);
        }
        Transforms.setNodes(editor, { type: 'heading', level: level as any }, { at: path });
      }
    },
    [editor],
  );

  const markdownKeyTriggers = useCallback(
    (e: KeyboardEvent<HTMLDivElement>) => {
      for (const hotkey of HOTKEYS) {
        if (hotkey.keys(e)) {
          e.preventDefault();
          toggleFormatting(editor, hotkey.mark);
        }
      }

      switch (e.key) {
        case 'Enter':
          enterTrigger(e);
          break;
        case 'Backspace':
          backspaceTrigger(e);
          break;
        case ' ':
          spaceTrigger(e);
          break;
      }
    },
    [backspaceTrigger, editor, enterTrigger, spaceTrigger],
  );

  const onKeyDown = useCallback(
    (e: KeyboardEvent<HTMLDivElement>) => {
      editingTag.current = null;
      if (disabled) {
        e.preventDefault();
        e.stopPropagation();
        return;
      }

      if (e.key === 'Enter' && singleLine) {
        e.preventDefault();
        return;
      }

      if (enableMarkdown) {
        Editor.withoutNormalizing(editor, () => markdownKeyTriggers(e));
      }
      if (enableDynamicData && e.key === '/') {
        menu.current?.trigger();
      } else {
        menu.current?.close();
      }
    },
    [disabled, editor, enableDynamicData, enableMarkdown, markdownKeyTriggers, singleLine],
  );

  useEffect(() => {
    if (!debug) {
      window.slate = undefined;
      return;
    }

    if (hasFocus) {
      window.slate = {
        editor,
        Editor,
        Transforms,
        Node,
        Path,
        getNodeAt: (path: Path) => Node.get(editor, path),
        get slateValue() {
          return editor.children;
        },
        get markdownValue() {
          return parseSlateJsToMarkdown(editor.children);
        },
      };
    }
  }, [debug, editor, hasFocus]);

  const borderStyle = useMemo(() => {
    if (disabled) {
      return 'border-gray-4 text-color-3';
    }

    if (errorState) {
      return 'border-accent-2';
    }

    if (hasFocus) {
      return 'border-accent-1';
    }

    return 'border-primary-1';
  }, [disabled, errorState, hasFocus]);

  const toolbar = useMemo(() => {
    if (enableMarkdown) {
      return (
        <RichTextActionBar
          editorRef={editorRef}
          hasFocus={!!hasFocus || menu.current?.isOpen}
          enableLanguageToggle={enableLanguageToggle}
          language={editorLanguage}
          onLanguageChange={setEditorLanguage}
          disabled={disabled}
          toolbarPosition={toolbarPosition}
        />
      );
    }
    return <></>;
  }, [disabled, editorLanguage, enableLanguageToggle, enableMarkdown, hasFocus, toolbarPosition]);

  return (
    <div className="relative">
      {label && <label className="text-color-3 text-dpm-12 absolute left-0 top-0 -mt-5 transition-opacity duration-150 ease-in-out">{label}</label>}
      <div
        {...dataAttributeProps(props)}
        className={`border-2 ${borderStyle} ${label ? 'mt-8' : ''} relative rounded-lg bg-white`}
        ref={editorRef}
        key={editorLanguage}
      >
        <Slate editor={editor} initialValue={value} onChange={onChange}>
          {toolbarPosition === 'top' && toolbar}
          {!enableMarkdown && enableLanguageToggle && (
            <div className={`relative z-10 ${singleLine ? (hasFocus ? 'float-right' : 'hidden') : hasFocus ? '' : 'invisible'}`}>
              <div
                className={`text-dpm-16 m-1 mt-[0.66rem] flex cursor-text items-center justify-end gap-1`}
                onMouseDown={(e) => {
                  e.preventDefault();
                  setTimeout(() => {
                    const editorDiv = editorRef.current?.querySelector('[data-slate-editor="true"]') as HTMLElement;
                    editorDiv.focus();
                  }, 100);
                }}
              >
                <TranslatableInputButtons selected={editorLanguage} onChange={setEditorLanguage} />
              </div>
            </div>
          )}
          <Editable
            data-slate-editor="true"
            maxLength={maxLength}
            autoFocus={autoFocus}
            spellCheck
            placeholder={inputPlaceholder}
            className={`w-full ${disabled ? 'border-gray-4 text-color-3' : 'text-primary-1'} placeholder-gray-2 bg-white ${styleClasses}`}
            style={{ opacity }}
            renderLeaf={renderLeaf}
            renderElement={renderElement}
            onFocus={onFocus}
            onBlur={onBlur}
            onKeyDown={onKeyDown}
            disabled={disabled}
            rows={rows}
          />
          {toolbarPosition === 'bottom' && toolbar}
        </Slate>
      </div>
      {enableDynamicData && !!hasFocus && (
        <p className="text-dpm-12 px-1" data-cy="placeholder-hint">
          {t('placeholder-menu.help')}
        </p>
      )}

      {enableDynamicData && (
        <PlaceholderSelectMenu
          allowExternalData={allowExternalDynamicData}
          targetRef={editingTag}
          ref={menu}
          form={form}
          referencedForms={referencedForms}
          menuAction={action}
          insertPlaceholder={(placeholder) => {
            let found = false;
            Transforms.removeNodes(editor, {
              at: [],
              match: (node) => {
                found = found || ('id' in node && node.id === placeholder.placeholder);
                return 'id' in node && node.id === placeholder.placeholder;
              },
            });

            if (!found) {
              Editor.deleteBackward(editor, { unit: 'character' });
            }

            Editor.insertNode(editor, {
              id: placeholder.placeholder,
              type: 'placeholderContainer',
              children: [{ text: placeholder.placeholder, type: 'tag' }],
            });

            onPlaceholdersChange([...(form.placeholders || []).filter((x) => x.placeholder !== placeholder.placeholder), placeholder]);

            setTimeout(() => {
              const editorDiv = editorRef.current?.querySelector('[data-slate-editor="true"]') as HTMLElement;
              Transforms.select(editor, { offset: 0, path: Path.next(Path.parent(editor.selection!.anchor.path)) });
              editorDiv.focus();
            }, 1);
          }}
        />
      )}
    </div>
  );
};
export default PlaceholderTextBox;
